@import "src/utils/global.module";

.next-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include sm-landscape {
    align-items: center;
  }

  @include md-landscape {
    align-items: center;
  }

  .next-button {
    background: transparent;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 20px;
    opacity: 1;
    color: black;
    font-weight: 350;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    &:hover {
      cursor: pointer;
    }

    @include sm-landscape {
      margin-top: 0;
      margin-left: 10px;
    }

    @include md-landscape {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}