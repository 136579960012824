.catch-me-main {
    width: 100%;
    height: 95%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .moving-parts {
        width: fit-content;
    }
}