@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');

$lato-font: 'Lato';
$correct-color: rgba(0, 128, 128, 0.4);
$wrong-color: rgba(255, 0, 0, 0.4);

:export {
  correctColor: $correct-color;
  wrongColor: $wrong-color;
  latoFont: $lato-font;
}

/* Extra Small Portrait and Landscape */
@mixin xs {
  @media only screen and (max-height: 325px) {
    @content;
  }
}

/* Extra Small Portrait */
@mixin xs-portrait {
  @media only screen and (max-device-width: 325px) and (orientation: portrait) {
    @content;
  }
}

/* Extra Small Landscape */
@mixin xs-landscape {
  @media only screen and (max-device-height: 325px) and (max-device-width: 570px) and (orientation: landscape) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin sm-portrait {
  @media only screen and (max-device-width: 480px) and (orientation: portrait) {
    @content;
  }
}

/*
/*Small Landscape */
@mixin sm-landscape {
  @media only screen and (max-device-width: 825px) and (max-device-height: 480px) and (orientation: landscape) {
    @content;
  }
}

/* Medium Portrait and Landscape */
@mixin md {
  @media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
    @content;
  }
}


/* Medium Portrait */
@mixin md-portrait {
  @media only screen and (min-device-width: 481px) and (max-device-width: 768px) and (orientation: portrait) {
    @content;
  }
}

/* Landscape */
@mixin md-landscape {
  @media only screen and (min-device-width: 826px) and (max-device-width: 1200px) and (max-device-height: 768px) and (orientation: landscape) {
    @content;
  }
}

/* large devices */
@mixin xl {
  @media only screen and (min-device-width: 769px) and (max-device-width: 1024px) {
    @content;
  }
}


// Extra large devices
@mixin xxl {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin ipad-landscape{
  @media(min-device-width: 1024px) and (max-device-width: 1200px) and (max-device-height: 1400px) and (orientation: landscape){
    @content;
  }
}

@mixin ipad-portrait{
  @media(min-device-width: 768px) and (max-device-width: 1024px) and (max-device-height: 1024px) and (orientation: portrait){
    @content;
  }
}

@mixin ipadPro-portrait {
  @media (min-device-width: 1000px) and (max-device-width: 1200px) and (max-device-height: 1400px) and (orientation: portrait) {
    @content;
  }
}

@mixin surface-portrait {
  @media (min-device-width: 500px) and (max-device-width: 550px) and (max-device-height: 750px) and (orientation: portrait) {
    @content;
  }
}

@mixin surface-landscape {
  @media (min-device-width: 700px) and (max-device-width: 750px) and (max-device-height: 550px) and (orientation: landscape) {
    @content;
  }
}
//
//320px—480px: Mobile devices
//481px—768px: iPads, Tablets
//769px—1024px: Small screens, laptops
//1025px—1200px: Desktops, large screens
//1201px and more —  Extra large screens, TV

