@import "../../utils/global.module";

.main-header {
  background: white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include sm {
    height: 40px;
  }
  @include sm-portrait {
    height: 40px;
  }
  @include sm-landscape {
    height: 40px;
  }
}