@import "src/utils/global.module";

.single-image-frame {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;

    @include md-portrait {
       margin-top: 15px;
    }
    @include md-landscape {
        margin-right:20px;
     }
    @include sm-portrait {
        padding-top: 35px;
        padding-bottom: 10px;
    }

    @include sm-landscape {
        padding: 5px;
        justify-content: flex-start;
    }

    .content {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include sm-landscape {
            width: fit-content;
            flex-wrap: nowrap;
        }

        @include md-landscape {
            width: fit-content;
            flex-wrap: nowrap;
        }

        .image-cover {
            width: 100%;
            height: fit-content;
            display: flex;
            justify-content: center;

            .image{
                width: 45vh;
                height: 45vh;
                transform: rotate(-3deg);
                box-shadow: 1px 2px 7px 3px rgba(0,0,0,0.47);

                @include sm-portrait {
                    width: 40vh;
                    height: 40vh;
                }

                @include sm-landscape {
                    width: 55vh;
                    height: 55vh;
                }

                @include md-landscape {
                    width: 45vh;
                    height: 45vh;
                }
            }
        }

        .btn-wrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            @include sm-landscape {
                align-items: center;
            }

            @include md-landscape {
                align-items: center;
            }

            .next-button {
                background: transparent;
                margin-top: 10px;
                width: 40px;
                height: 40px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                line-height: 20px;
                border: 2px darkgrey solid;
                border-radius: 50px;
                opacity: 1;
                color: black;
                font-weight: 350;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

                &:hover {
                    cursor: pointer;
                }

                @include sm-landscape {
                    margin-top: 30px;
                    margin-left: 10px;
                }

                @include md-landscape {
                   
                    margin-left: 10px;
                }
            }
        }
    }
}
