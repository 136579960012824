@import "../../utils/global.module";

.group-main-container {
    width: 100%;
    height: 100%;
    position: relative;

    .columns {
        position: absolute;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        > div {
            display: flex;
            flex: 1;
            border-right: lightslategray dashed 1px;
            position: relative;

            > div {
                width: 100%;
                height: fit-content;
                background-color: rgba(28, 27, 27, 0.2);

                > h3 {
                    font-size: 25px;
                    font-family: $lato-font;
                    text-align: center;
                    color: #404040;

                    @include sm-landscape {
                        font-size: 17px;
                    }

                    @include sm-portrait {
                        font-size: 14px;
                    }
                }
            }

            span {
                position: absolute;
                top: calc(50% - 15px);
                padding: 0 50px;
                z-index: 100;
                height: fit-content;

                img {
                    height: 60px;
                    opacity: 0.4;
                    filter: alpha(opacity=40);
                }

                @include sm-portrait {
                    width: fit-content;
                    padding: 0;
                    right: 0;
                    top: calc(83%);
                }

                &:hover {
                    cursor: pointer;
                }
            }


            &:last-child {
                border-right: none;
                border-left: lightslategray dashed 1px;

                span {
                    right: 0;

                    @include sm-portrait {
                        left: 0;
                    }
                }
            }
        }
    }

    .container {
        overflow: hidden;
        width: 100%;
        height: 100%;

        > div {
            position: fixed;
            width: 100%;
            height: 100%;
            will-change: transform;
            display: flex;
            align-items: center;
            justify-content: center;
            touch-action: none;

            @include sm-portrait{
                height: 90%;
            }
            > div {
                background-color: white;
                padding: 20px 20px 60px 20px;
                width: 51vh;
                height: 50vh;
                max-width: 400px;
                max-height: 450px;
                will-change: transform;
                border-radius: 10px;
                border: 1px solid lightslategray;
                // box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);

                > div {
                    font-family: 'Lato';
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0.1em;
                    word-spacing: 0.3em;
                }

                @include sm-landscape {
                    padding: 10px 10px 30px 10px;
                }

                @include sm-portrait {
                    width: 65vw;
                    height: 70vw;
                }
            }
        }
    }

    &:focus {
        outline: none;
    }
}

  