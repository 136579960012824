@import "../../utils/global.module";

.couple-text-main-wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;

  .couple-text-main {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    touch-action: none;
    height: fit-content;

    div > {
      height: fit-content;
      touch-action: none;
    }

    .items-row {
      display: flex;
      max-width: 1000px;
      width: 100%;

      .wrapper {
        display: flex;
        width: 24.5%;
        margin: 3px;
        position: relative;

        .box {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          color: rgba(10, 10, 10, 0.98);
          font-size: 14.5px;
          border-radius: 5px;
          font-weight: 600;

          @include xs-portrait {
            font-size: 10px;
          }

          span {
            display: flex;
            height: fit-content;
            text-align: center;
          }
        }

        .back {
          position: absolute;
          background: teal;
          box-shadow: rgba(0, 0, 0, 0.15) 0 1px 2px 0;
        }

      }
    }
  }
}
