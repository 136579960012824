@import "../../utils/global.module";

.close-button-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  border: 1.5px black solid;
  border-radius: 50px;
  color: black;
  position: absolute;
  left: 15px;
  bottom: 15px;
  font-weight: 900;

  img{
    width: 25px;

    @include sm{
      width: 20px;
    }

    @include sm-landscape {
     width: 20px;
    }
  }

  @include sm {
    width: 37px;
    height: 37px;
    font-size: 15px;
  }

  @include sm-portrait {
    width: 37px;
    height: 37px;
    font-size: 15px;
  }

  @include sm-landscape {
    width: 37px;
    height: 37px;
    font-size: 15px;
  }

  &:hover {
    cursor: pointer;
  }
}