@import "src/utils/global.module";

.container-image-select-wrapper {
    width: 100%;
    max-width: 800px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container-image-select{
        height: fit-content;
        max-width: 770px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include ipadPro-portrait{
            max-width: 800px;
        }
        @include surface-landscape {
            max-width: 500px;
            margin-top: 35px;
        }

        @include sm-landscape {
            max-width: 800px;
        }



        .single-image{
            width: 50%;
            padding: 5px;
            box-sizing: border-box;
            position: relative;

            .img {
                border-radius: 15px;
                padding: 0;
                width: 100%;
                height: 100%;
            }

            .overlay {
                top: 5px;
                border-radius: 15px;
                position: absolute;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                background: black;
                opacity: 0.3;
            }
        }
    }
}
