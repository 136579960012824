@import "../../utils/global.module";

.layout-main {
  height: 100%;
  display: flex;
  flex-direction: column;

  .container-wrapper {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;

    .container {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .withBackground {
      opacity: 0.05;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: repeat;
      background-size: 700px auto;
     
      @include sm-landscape{
        background-size: 400px auto;
      }

      @include sm-portrait{
        background-size: 400px auto;
      }
    }
  }

  h1 {
    font-size: 16px;
    text-align: center;

    @include sm {
      font-size: 12px;
      text-align: center;
    }
    @include sm-portrait {
      font-size: 12px;
      text-align: center;
    }
    @include sm-landscape {
      font-size: 12px;
      text-align: center;
    }
  }
}