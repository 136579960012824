@import "../../utils/global.module";

.main-container-question-bar {

    background-color: rgb(59, 58, 58);
    height: 60px;
    text-align: center;
    color:white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
        .tittle{
            width: auto;
            padding:0 10px;

            @include sm-portrait {
                font-size: 12px;
            }

            @include sm-landscape {
                font-size: 12px;
            }
        }
        .nextButton{
            background-color: rgb(82, 82, 82);
            height: 60px;
            width: 60px;
            color:white;
            border: none;
            font-size: 18px;
            @include sm {
                height: 45px;
                width: 45px;
            }
            @include sm-portrait {
                height: 45px;
                width: 45px;
            }

            @include sm-landscape {
                height: 45px;
                width: 45px;
            }
            @include xs{
                height: 45px;
                width: 45px;
            }
        }
    
    @include sm {
        height: 45px;
    }
    @include sm-landscape {
        height: 45px;
    }


    // @include sm-portrait {
    //     height: fit-content;
    // }

    // @include sm-landscape {
    //     height: fit-content;
    // }

}