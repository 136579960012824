@import "../../utils/global.module";


.image-game-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @include sm-landscape {
    flex-direction: row;
  }

  .image {
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    @include sm-landscape {
      width: 60%;
    }

    @include md {
      padding-bottom: 10px;
    }

    @include surface-landscape {
      padding-bottom: 10px;
    }

    img {
      max-width: 500px;
      display: flex;
      width: 100%;

      @include surface-landscape {
        width: 350px;
      }
    }

  }

  .custom-styles {
    > div {

      > div {
        @include sm-portrait {
          margin: 0 5px 5px;
          padding: 10px 0;
        }
      }
    }
  }

}