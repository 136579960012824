@import "utils/global.module";

.App {
  height: 100vh;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  @include sm {
    font-size: 0.7em;
  }

  @include sm-landscape {
    font-size: 0.7em;
  }
}

h3 {
  @include sm {
    font-size: 0.5em;
  }

  @include sm-landscape {
    font-size: 0.5em;
  }
}