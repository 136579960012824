@import "../../utils/global.module";

.identify-image-main {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .key-board {
    width: 58%;

    .key-board-buttons-main{
      align-content: center;
    }
  }

  .image-wrapper {
    display: flex;
    width: 42%;
  }

  @include sm-portrait {
    .image-wrapper {
      width: 100%;
      height: 50%;
    }

    .key-board {
      width: 100%;
      height: 50%;
    }
  }

  @include md-portrait {
    .image-wrapper {
      width: 100%;
      height: 50%;
    }

    .key-board {
      width: 100%;
      height: 50%;
    }
  }

  @include ipadPro-portrait {
    .image-wrapper {
      width: 100%;
      height: 50%;
    }

    .key-board {
      width: 100%;
      height: 50%;
    }
  }
}