@import "../../utils/global.module";

.step-counter-main {
  height: fit-content;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  width: 50px;

  @include sm {
    font-size: 12px;
  }
  @include sm-portrait {
    font-size: 13px;
  }
  @include sm-landscape {
    font-size: 12px;
  }
}