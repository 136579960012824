@import "../../utils/global.module";


.order-main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .order-container{
        padding:7px;
        width: 100%;
        height: fit-content;
        margin-left: 15px;
        margin-right: 15px;

        .hr {
            width: 100%;
            height: 1px;
            border-top: 1px solid black;
        }

        .word{
            width:auto;
            padding:12px;
            height: 20px;
            background-color: white;
            font-size: 15px;
            text-align: center;
            border-radius: 6px;
            // box-shadow: 3px 3px #888888;
            align-items: center;
            display: flex;
            justify-content: center;
            margin:3px;

            @include xxl{
                padding:20px;
                font-size: 17px;
            }
            @include xs{
                padding:5px !important;
            }
            @include xs-portrait{
                padding:8px;
                font-size: 12px;
            }
            @include sm-landscape{
                font-size: 11px;
                padding:8px;
                width:auto;
                height: 20px;
                background-color: white;
                text-align: center;
                border-radius: 6px;
                // box-shadow: 3px 3px #888888;
                align-items: center;
                display: flex;
                justify-content: center;
                margin:2px;
            }
            @include sm-portrait{
                font-size: 12px;
                padding:8px;
                width:auto;
                height: 20px;
                background-color: white;
                text-align: center;
                border-radius: 6px;
                // box-shadow: 3px 3px #888888;
                align-items: center;
                display: flex;
                justify-content: center;
                margin:2px;
            }
        }
        .word-down{
            width:auto;
            padding:20px;
            height: 17px;
            background-color: white;
            font-size: 15px;
            text-align: center;
            border-radius: 6px;
            box-shadow: 2px 2px #888888;
            align-items: center;
            display: flex;
            justify-content: center;
            margin:5px;

            @include sm-landscape{
                font-size: 12px;
                padding:8px;
                width:auto;
                height: 20px;
                background-color: white;
                text-align: center;
                border-radius: 6px;
                box-shadow: 2px 2px #888888;
                align-items: center;
                display: flex;
                justify-content: center;
                margin:5px;
            }
            @include sm-portrait{
                font-size: 12px;
                padding:8px;
                width:auto;
                height: 20px;
                background-color: white;
                text-align: center;
                border-radius: 6px;
                box-shadow: 2px 2px #888888;
                align-items: center;
                display: flex;
                justify-content: center;
                margin:5px;
            }
            
        }
        .words{
            flex-direction: row;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 50px;
            margin-top: 20px;

            @include xs-landscape{
                margin-bottom: 0px;
                // margin-top: 20px;
                //justify-content: flex-start;
            }
            .wordR{
                @include xs-portrait{
                    flex-wrap: wrap;
                    justify-content: center;
                }
                @include sm-portrait{
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
        .words-down{
            margin-top: 15px;
            flex-direction: row;
            align-items: center;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @include sm-landscape{
                justify-content:  center;
            }
            @include xs{
                margin-top: 10px;
            }
            @include xs-portrait{
                margin-top: 10px;
            }
        }
        .button-div{
            width: 100%;
            display: flex;
            justify-content: center;

            .button-comprobar{
                background-color: lightslategrey;
                border:none;
                border-radius: 10px;
                color:white;
                padding:20px 40px;
                font-size: 15px;
                margin-top: 30px;

                @include sm-landscape{
                    margin-top:10px;
                    padding:10px 20px 10px 20px;
                    font-size: 12px;
                }
                @include xs-portrait{
                    margin-top: 10px;
                    padding:10px 20px 10px 20px;
                    font-size: 12px;
                }
            }

        }

    }
}


