@import "src/utils/global.module";

.response-container-game-text-main {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    max-width: 700px;

    @include surface-landscape {
        max-width: 400px;
    }

    .text-container {
        display: flex;
        width: 48%;
        justify-content: center;
        height: 150px;
        max-width: 250px;
        padding-bottom: 10px;

        @include sm-portrait{
            max-width: none;
            width: 90%;
            height: 60px;
        }

        @include sm-landscape {
            height: 100px;
        }

        @include surface-landscape {
            height: 100px;
            width: 150px;
        }

    }

    .btn-style {
        text-align: center;
        display: flex;
        font-size: 18px;
        width: 100%;
        height: auto;

        @include sm {
            font-size: 16px;
        }
        @include sm-portrait {
            font-size: 14px;
        }
        @include xs{
            font-size: 14px;
        }
        @include md {
            font-size: 16px;
        }
        @include surface-landscape {
            font-size: 12px;
        }
    }
}