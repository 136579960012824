@import "../../utils/global.module";

.heart-meeter-main {
  height: fit-content;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  @include sm-portrait {
    padding: 10px 10px;
  }

  .one-heart {
    position: relative;
    width: 25px;
    height: 25px;

    @include sm {
      width: 15px;
      height: 15px;
    }

    @include sm-portrait {
      width: 15px;
      height: 15px;
    }

    @include sm-landscape {
      width: 15px;
      height: 15px;
    }


    > img {
      position: absolute;
      width: 25px;

      @include sm-portrait {
        width: 15px;
      }

      @include sm-landscape {
        width: 15px;
      }

      @include sm {
        width: 15px;
      }
    }


  }
}