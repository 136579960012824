@import "../../utils/global.module";


.show-answers-container{
   
    .background{
        background-image: url('https://www.academons.com/img/fondos/fondo_cienciassociales.png');
        background: '#e6f4da';
        opacity: 0.05;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: repeat;
        background-size: 700px auto;
    }
    height: 100%;
    width: 100%;

    .show-answers-header{
        height: 10%;
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .left{
            background-color: rgb(168, 165 , 166);
            width: 100%;
            height: 100%;
            justify-content: center;
            display: flex;
            align-items: center;
            h3{
                font-family: 'Lato';
                color: white;

                @include sm-landscape{
                    font-size: 0.8em;
                }
            }
        }

        .right{
            display: flex;
            align-items: center;
            background-color: rgb(17, 95, 94);
            width: 100%;
            height: 100%;
            h3{
                color: white;
                font-family: 'Lato';
                margin-left: 20px;

                @include sm-landscape{
                    font-size: 0.8em;
                }
            }
        }
    }

    .show-answers-content{
        // display: flex;
        // flex-direction: column;
        // flex-wrap: wrap;
        .questions{
            display: flex;
            justify-content: flex-end;
            width: 50%;
            background-color: rgb(237, 237, 237);
            align-items: center;
            text-align: right;
            border-left: 1px solid grey;
            border-right: 1px solid grey;
            border-bottom: 1px solid grey;
            font-family: 'Lato';
            font-weight: 600;

            
            div{

                padding:10px;
                margin-right: 20px;

                @include sm-landscape{
                    font-size: 11.5px;
                }
            }
        }
        .responses{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50%;
            background-color: white;
            border-bottom: 1px solid grey;
            border-left: 1px solid grey;
            font-family: 'Lato';
            font-weight: 600;

            div{

                padding:10px;
                margin-right: 20px;

                @include sm-landscape{
                    font-size: 11.5px;
                }
            }
            .percentage{
                p{
                    font-family: 'Lato';
                    font-weight:bold;
                    font-stretch: normal;
                    font-style: normal;
                    margin-right: 20px;
                }
            }
        }
    }
}
