@import "../../utils/global.module";

.progress-bar-main {
  width: 100%;
  height: 10px;
  background: aliceblue;
  @include sm-landscape{
    height: 4px;
  }
  @include sm-portrait{
    height: 4px;
  }
  
  
  .progress-bar {
    height: 100%;
    width: 0;
    background-image: linear-gradient(130deg, #009bb5, #009bb5);
  }
}

