@import "../../utils/global.module";

.carousel-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    justify-content: center;
    height: fit-content;
    max-width: 800px;
    flex-wrap: wrap;
    align-items: center;
  }
}