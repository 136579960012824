@import "../../utils/global.module";

.ranking-container-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include xs-portrait{
    margin-top: 20px;
  }

  .wrapper {
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include sm-landscape {
      flex-direction: row;
      margin-top: 35px;
      max-width: 500px;
    }

    @include xs-landscape{
      margin-left: 55px;
      margin-top: 30px;
    }
    .soring-area {
      display: flex;
      width: 100%;
      height: auto;
      padding-bottom: 10px;


      .text-styles {
        display: flex;
        border-radius: 5px;
        line-height: 40px;
        font-size: 14.5px;
        margin: 3px;
        width: 100%;
        height: 40px;

        @include sm {
          height: 30px;
          line-height: 30px;
        }
        @include sm-portrait {
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          margin: 5px;
        }
      }

      .col-numbers {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        > div {
          @extend .text-styles;
          background-color: black;
          text-align: right;
          color: white;
          justify-content: center;
          align-items: center;
          width: 80%;

          @include sm-portrait {
            width: 30px;
          }
        }
      }

      .col-list {
        display: flex;
        flex: 1;
        padding-right: 10px;

        .list {
          position: relative;
          width: 90%;

          @include sm-portrait {
            width: 96%;
          }

          > div {
            @extend .text-styles;
            position: absolute;
            background-color: lightblue;
            color: black;
            padding-left: 10px;
            touch-action: none;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            @include sm-portrait {
              font-size: 10px;
            }

            @include sm-landscape {
              font-size: 10px;
            }
          }
        }
      }
    }

    .button-ranking {
      background-color: lightslategrey;
      border-radius: 10px;
      color: white;
      border: none;
      padding: 10px 20px 10px 20px;
      font-size: 14px;

      @include sm {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }

      @include sm-portrait {
        margin-top: 10px;
      }

      @include sm-landscape {
        margin-left: 20px;
      }

    }

  }
}
