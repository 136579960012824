@import "../../utils/global.module";

.game-test-text {
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;

    .tittle {
        width: 100%;
        display: flex;
        justify-content: center;

        @include sm-portrait{
            margin-bottom: 0px;
        }
        @include xs-portrait{
            margin-bottom: 10px;
        }
        @include sm-landscape{
            width: 85%;
        }
        > div {
            width: 100%;
            padding: 0 10px 40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 600px;

            @include sm-landscape {
                display: flex;
                padding-bottom: 0px;
                flex-wrap: nowrap;
            }

            @include xs-landscape{
                padding-bottom: 0px;
            }
            @include sm-portrait {
                padding-bottom: 20px;
            }

            h1 {
                font-family: $lato-font;
                display: flex;
                font-size: 36px;
                justify-content: center;
                align-items: center;

                @include sm-landscape {
                    font-size: 20px;
                    width: auto;
                }

                @include sm-portrait {
                    font-size: 22px;
                }
            }
        }
    }

    .btn {
        position: absolute;
        align-items: center;
        width: fit-content;
        height: fit-content;
        top: 0;
        right: 0;
        padding: 10px;

        > div {
            margin: 0;
        }
    }

    .less-than-four {
        max-width: none;
        flex-wrap: nowrap;

        @include sm-portrait {
            flex-wrap: wrap;
        }
    }
}