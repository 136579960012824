@import "src/utils/global.module";

.map-container-main {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;

  @include sm-portrait {
    flex-wrap: wrap;
  }

  .next-btn {
    position: absolute;
    top: 0;
    right: 10px;
  }

  .img-container {
    width: 100%;
    height: auto;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sm-landscape {
      width: 60%;
    }

    > div {
      position: relative;
      max-width: 650px;

      @include sm-landscape {
        width: 80vh;
      }

      @include surface-landscape {
        width: 80vh;
      }

      img {
        width: 100%;
        height: auto;
      }

      > div {
        position: absolute;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          width: 24px;
          height: 24px;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 50px;
          border: black 2px solid;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            width: 5px;
            height: 5px;
            background: black;
            border-radius: 50px;
          }
        }

        .correct {
          background-color: rgba(2, 121, 2, 0.5);
        }

        .wrong {
          background-color: rgba(255, 0, 0, 0.5);
        }
      }

      .single-point {
        > div {
          border: none;
          background: none;
          width: 100%;
          height: 100%;
          position: relative;

          >img {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50%;
            height: auto;
          }
        }
      }
    }
  }

  .button-container {
    width: 40%;
    justify-content: center;
    flex-wrap: wrap;
    display: none;
    align-items: center;

    @include sm-landscape {
      display: flex;
    }

    @include sm-portrait {
      display: flex;
      width: 100%;
    }

    > div {
      width: 90%;
      height: 50px;
    }
  }
}