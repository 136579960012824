@import "../../utils/global.module";

.game-word-search-container {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;

  @include sm-portrait {
    flex-wrap: wrap;
  }

  .words-container {
    background-color: rgba(212, 212, 212, 0.705);
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include sm-portrait {
      width: 100%;
    }

    .word-container {
      text-align: center;
      width: 100%;

      p {
        margin: 5px;

        @include sm {
          font-size: 13px;
          margin: 2px;
        }
        @include sm-portrait {
          font-size: 13px;
          margin: 2px;
        }
      }
    }
  }

  .matrix-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include sm-portrait {
      width: 100%;
    }

    .cell-wrapper {
      height: 100%;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .row {
        width: fit-content;
        display: flex;

        .cell {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          background: white;
          border: 1px solid lightslategray;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          // box-shadow: 1px 0 2px 1px rgba(0,0,0,0.41);

          @include sm-portrait {
            width: 30px;
            height: 30px;
          }

          @include sm-landscape {
            width: 26px;
            height: 26px;
          }

          @include xs-landscape {
            width: 23px;
            height: 23px;
          }

          @include xs-portrait {
            width: 25px;
            height: 25px;
          }

          @include surface-portrait {
            width: 35px;
            height: 35px;
          }

          @include surface-landscape {
            width: 37px;
            height: 37px;
          }

        }
      }
    }
  }

}