.select-answers-main{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .container {
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    max-height: 1000px;
    height: 50%;
    align-items: center;
    justify-content: center;
    // margin-top: 70px;
  }
}