@import "../../utils/global.module";

.key-board-buttons-main {
  .full-page {
    width: 100%;
    height: 50%;
  }
  .main-styles {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }

  width: 100%;
  height: 90%;
  @extend .main-styles;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .answer-area {
    @extend .full-page;
    @extend .main-styles;
    
    flex-wrap: wrap;
    > div{
      margin:0px;
      margin-top: 50px;
    }
    @include sm{
      margin-left: 10px;
    }
  }

  .options-area {
    @extend .full-page;
    @extend .main-styles;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  &:focus {
    outline: none;
  }
}