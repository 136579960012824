@import "../../utils/global.module";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap');

.button-main {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 30px;
  min-width: 20px;
  width: fit-content;
  margin: 3px;
  border: #D6D6D6 solid 1px;
  font-weight: 700;

  @include ipad-landscape{
    padding: 15px 20px;
    font-size: 20px;
  }
  @include ipad-portrait{
    padding: 15px 20px;
    font-size: 20px;
  }
  @include xxl{
    padding: 20px 25px;
    font-size: 20px;
  }
  @include ipadPro-portrait{
    padding: 20px 25px;
    font-size: 20px;
  }
  
  @include sm-portrait{
    padding: 2px 7px;
    font-size: 14px;
  }
  @include xs-portrait{
    padding: 2px 7px;
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
  }

  > span {
    height: fit-content;
    width: fit-content;
    font-family: $lato-font;
  }

  &--bigger {
    margin: 10px;

    @include sm {
      margin: 3px;
      height: 37px !important;
      font-size: 12px;
      min-width: 75px !important;
    }
    @include sm-portrait {
      margin: 3px;
      height: 30px !important;
      font-size: 14px;
      min-width: 100px !important;
    }

    @include sm-landscape {
      margin: 3px;
      height: 30px !important;
      font-size: 14px;
      min-width: 100px !important;
    }

    @include xs-portrait {
      margin: 3px;
      height: 30px !important;
      font-size: 10px;
      min-width: 100px !important;
    }
    @include xs {
      margin: 3px;
      height: 30px !important;
      font-size: 10px;
      min-width: 75px !important;
    }
  }
}