@import "../../utils/global.module";

.white-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .input{
        width: 100%;
        background-color: rgb(83, 88, 94);
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;

        @include sm {
            height: 40px;
        }

        @include sm-landscape {
            height: fit-content;
        }

        @include md-portrait{
            height: 80px;
        }

        input{
            height: 55%;
            width: 40%;
            text-align: center;
            margin: 3px;
            min-height: 30px;

            &:focus {
                outline: none !important;
            }

            @include sm-portrait{
                width: 70%;
            }
        }
    }

    .list{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;

        @include sm-portrait{
            height: 85%;
        }

        $listGrey: #e5eaec;

        .list-wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            @include md-portrait{
                width: 65%;
            }
            @include sm{
                font-size: 12px;
            }
            @include sm-portrait{
                font-size: 14px;
                width: 80%;
            }

            @include sm-landscape {
                width: 60%;
            }

            >div {
                &:first-child {
                    position: absolute;
                    height: 100%;
                    width: 2px;
                    left: 50px;
                    border-left: 1px solid #F2E3DF;
                    border-right: 1px solid #F2E3DF;
                    z-index: 4;
                }

                &:nth-last-child(2) {
                    border-bottom: none;
                    box-shadow: 0 3px 3px -1px rgba(0,0,0,0.3);
                }
            }

            .list-item {
                display: flex;
                align-items: center;
                flex-direction: row;
                text-align: center;
                background-color: white;
                border-bottom: 1px solid $listGrey;
                z-index: 3;
                p {
                    margin: 10px;
                }

                .check{
                    color: teal;
                    font-weight: bolder;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 40px;

                    //@include sm{
                    //    padding:5px;
                    //}
                    //@include sm-portrait{
                    //    padding:10px;
                    //}

                    @include sm-landscape {
                        height: 30px;
                    }

                }
            }

            .blank {
                height: 25px;
            }

            .blank-pages {
                position: absolute;
                width: 100%;
                height: 10px;
                bottom: -6px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;


                > div {
                    height: 3px;
                    background: white;
                    width: 99%;
                    box-shadow: 0 2px 2px -1px rgba(0,0,0,0.5);
                    z-index: 1;

                    &:last-child {
                        width: 98%;
                        z-index: 0;
                        box-shadow: 0 1px 1px -1px rgba(0,0,0,0.7);
                    }
                }
            }
        }
    }
}